import React, { useState } from "react";
import { useMemo } from "react";
import { createContext } from "react";
import { Layout } from "../components/Layout";

export const ThemeContext = createContext({ isDark: false, modalStatus: false, modalBuyStatus: false });

export const ThemeProvider = ({children}) => {
    const [isDark, setIsDark] = useState(false);
    const [modalStatus, setModalStatus] = useState(false);
    const [modalBuyStatus, setModalBuyStatus] = useState(false);

    const value = useMemo(() => ({ isDark, setIsDark, modalStatus, setModalStatus, modalBuyStatus, setModalBuyStatus }), [isDark, modalStatus, modalBuyStatus]);

    return <ThemeContext.Provider value={value}>
        <Layout>
            {children}
        </Layout>
    </ThemeContext.Provider>
}