import React from "react";
import useTheme from "../../hooks/useTheme";
import './style.scss';

export const Layout = ({children}) => {

  const {isDark} = useTheme();

  return (
    <div className={`layout ${(isDark && 'dark')}`}>{children}</div>
  )
}